<template functional>
  <div class="py-4">
    <slot/>
    <div :style="{border: `solid ${props.width}px ${props.color}`, display: ''}"/>
  </div>
</template>

<script>
  export default {
    name:  'AppDivider',
    props: {
      width: {
        type:    Number,
        default: .05,
      },
      color: {
        type:    String,
        default: 'white',
      },
    },
  }
</script>