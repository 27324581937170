<template>
  <v-menu
    v-model="isActive"
    :close-on-content-click="false"
    offset-y bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <slot/>
      </div>
    </template>
    <v-row dense justify="center"
           align="center"
           class="text-center primary px-4">
      <v-col cols="12" class="elevation-1 title">
        Pitch class
      </v-col>
      <v-col class="elevation-1">
        <v-row dense>
          <v-col v-for="pc in pitchClasses" :key="pc">
            <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
                   :small="$vuetify.breakpoint.lgAndUp"
                   class="text-capitalize"
                   :color="pitchClass === pc ? 'secondary' : 'primary darken-2'"
                   @click="updatePitchClass(pc)">
              {{ pc }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider/>
      </v-col>
      <v-col cols="12" class="elevation-1 title">
        Octave
      </v-col>
      <v-col class="elevation-1">
        <v-row dense>
          <v-col v-for="oct in octaves" :key="oct">
            <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
                   :small="$vuetify.breakpoint.lgAndUp"
                   :color="octave === oct ? 'secondary' : 'primary darken-2'"
                   @click="updateOctave(oct)">
              {{ oct }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center py-4">
        <v-btn rounded width="100" :small="$vuetify.breakpoint.xsOnly"
               class="text-capitalize"
               color="success"
               @click="play">
          {{ `${ pitchClass }${ octave }` }}
          <app-icon icon="play-arrow" :size="20"/>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pb-3">
        <v-btn rounded color="warning"
               :small="$vuetify.breakpoint.xsOnly"
               @click="startPitchDetect">
          Detect..
          <app-icon icon="hearing" size="16"/>
        </v-btn>
      </v-col>
      <v-col v-if="detectPitch">
        <check-pitch @done="pitchDetected"/>
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
import { getConstants } from 'framework/helpers/note-art-helpers'
import { playNotes }    from 'framework/helpers/audio-playing/play-notes'
import CheckPitch       from 'sections/CheckPitch'
import { noteToObject } from 'framework/utilities/Utilities'

export default {
  name:       'SelectNote',
  components: { CheckPitch },
  props:      {
    pitchClass:    { required: true, type: String },
    octave:        { required: true, type: Number },
    octaves:       { type: Array, default: () => [0, 1, 2, 3, 4, 5, 6, 7, 8] },
    pitchClassVar: { type: String, required: true },
    octaveVar:     { type: String, required: true },
  },
  data() {
    return {
      pitchClasses: getConstants().flatClassNotes,
      isActive:     false,
      detectPitch:  false,
    }
  },
  computed:   {},
  methods:    {
    play() {
      playNotes({ notes: [`${ this.pitchClass }${ this.octave }`] })
    },

    updatePitchClass(pc) {
      this.$emit('updated', { setting: this.pitchClassVar, value: pc })
    },

    updateOctave(octave) {
      this.$emit('updated', { setting: this.octaveVar, value: octave })
    },

    startPitchDetect() {
      this.detectPitch = !this.detectPitch
      if(this.detectPitch) {
        this.$store.dispatch('view/setSnackbar', { text: 'Please hold a note for 2 seconds.', active: true })
      }
    },

    pitchDetected(note) {
      const { pitchClass, octave } = noteToObject(note)
      this.updatePitchClass(pitchClass)
      this.updateOctave(octave)
      this.$store.dispatch('view/setSnackbar', { text: `Success, Detected ${ note }!`, active: true })
      this.detectPitch = false
    },
  },
}
</script>