import store from 'src/store'
import audioManager from 'framework/models/AudioManager'

export function playNotes({ notes, dur = '4n', time, instrument, velocity }) {
  dur = store.getters['settings/isSustain'] ? '10' : dur
  if (!instrument) {
    instrument         = store.getters['players/getMainPlayerInstrument']
  }
  audioManager[instrument].play({
    notes,
    dur,
    time,
    velocity,
  })
}
