<template>
  <div>
    <v-row no-gutters justify="center" align="center" class="elevation-1">
      <v-col cols="1"/>
      <v-col>
        <span class="font-weight-bold display-1">
          {{ note }}
        </span>
      </v-col>
      <v-col cols="1">
        <v-btn icon @click="settings=!settings">
          <app-icon icon="settings" size="16"/>
        </v-btn>
      </v-col>
    </v-row>
    <div class="pt-10 elevation-1">
      <v-slider readonly :min="-45" :max="45" :value="detuneSliderValue" thumb-label="always"
                :style="{backgroundImage:
                  'linear-gradient(to right, rgba(175,35,35,1) 12%, rgba(53,207,20,1) 50%, rgba(175,35,35,1) 88%)'}"/>
    </div>
    <div v-if="settings" class="py-4">
      <v-slider min="1" max="100"
                v-model="minSignal"
                label="Sensitivity(high to low)"
                color="accent"
                style="color: black !important;"/>
    </div>
  </div>
</template>

<script>
import { mapActions }                             from 'vuex'
import { pitchDetection }                         from 'framework/models/PitchDetection'
import { Frequency, UserMedia, Waveform }         from 'tone'
import { centsOffFromFrequency, frequencyToMidi } from 'framework/utilities/Utilities'

let waveform, userMedia, prevKey = {}, rafId

export default {
  name: 'PitchDetector',

  mounted() {
    waveform  = new Waveform(2048)
    userMedia = new UserMedia()
    userMedia.connect(waveform)
    this.openUserMedia()
    this.sensitivity = pitchDetection.minSignal
  },

  destroyed() {
    this.closeUserMedia()
  },

  props: {
    shouldCapture: Boolean
  },

  data() {
    return {
      transcription:       [],
      detection:           {},
      isMicrophoneBlocked: false,
      settings:            false,
      sensitivity:         0
    }
  },

  computed: {
    note: function() {
      return this.detection.note || '-'
    },

    detune: function() {
      return this.detection.detune || '-'
    },

    detuneSliderValue: function() {
      return this.detection.detune || 0
    },

    minSignal: {
      get() {
        return this.$store.getters['settings/getMicSensitivity']
      },
      set(val) {
        this.$store.dispatch('settings/setMicSensitivity', val)
      }
    }
  },

  methods: {
    ...mapActions('piano-state', ['setPianoKeys']),
    getPitch() {
      prevKey && this.setPianoKeys({ ...prevKey, val: false })
      this.detection = {}
      if(this.shouldCapture && userMedia.state == 'started') {
        const frequency = pitchDetection.detect(waveform.getValue())
        const midi      = frequencyToMidi(frequency)
        const detune    = centsOffFromFrequency(frequency, midi)
        if(midi) {
          const note = Frequency(midi, 'midi').toNote()

          const keyColor = getKeyColor(detune)
          const currKey  = { keys: [note], keyColor, val: true }

          this.detection = { note, detune }
          this.setPianoKeys(currKey)

          prevKey = currKey
        }
      }
      this.$emit('media-input', this.detection.note)
      rafId = setTimeout(this.getPitch, 120)
    },

    toggleUserMedia() {
      ( !!userMedia && userMedia.state == 'started') ? this.closeUserMedia() : this.openUserMedia()
    },

    startCapturing() {
      rafId = requestAnimationFrame(this.getPitch)
    },

    stopCapturing() {
      cancelAnimationFrame(rafId)
    },

    openUserMedia() {
      userMedia.open().then(() => {
        this.isMicrophoneBlocked = false
      }).catch(() => {
        this.isMicrophoneBlocked = true
      })
    },

    closeUserMedia() {
      userMedia.close()
      cancelAnimationFrame(rafId)
    }
  },
  watch:   {
    shouldCapture: {
      immediate: true,
      handler(value) {
        value ? this.startCapturing()
              : this.stopCapturing()
      }
    }
  }
}

/**
 * Get piano key color by detune.
 * @param detune
 * @returns {string}
 */
function getKeyColor(detune) {
  const value = Math.abs(detune)
  if(value > 30) {
    return 'deep-orange'
  } else if(value >= 15) {
    return 'green lighten-3'
  }
  return 'success'
}
</script>
