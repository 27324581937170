<template>
  <div>
    <pitch-detector :should-capture="true" @media-input="updateInput"/>
  </div>
</template>

<script>
import PitchDetector from 'src/sections/PitchDetector'

export default {
  name:       'CheckRange',
  components: { PitchDetector },
  data() {
    return {
      currNote:  '',
      timeoutID: '',
    }
  },
  methods:    {
    updateInput(note) {
      if(note && note !== this.currNote) {
        clearTimeout(this.timeoutID)
        this.currNote  = note
        this.timeoutID = setTimeout(() => {
          this.$emit('done', this.currNote)
        }, 2000)
      } else if( !note) {
        clearTimeout(this.timeoutID)
      }
    },
  },
}
</script>

<style scoped>

</style>