<template>
  <div>
    <app-divider>
      <span class="font-weight-medium title">
        Setup
      </span>
    </app-divider>
    <v-row align="center">
      <v-col>
        From
      </v-col>
      <v-col>
        <select-note :pitch-class="pitchClassStart"
                     pitch-class-var="pitchClassStart"
                     :octave="octaveStart"
                     octave-var="octaveStart"
                     :octaves="[2,3,4,5,6]"
                     @updated="setState">
          <v-text-field hide-details dense readonly :value="`${pitchClassStart}${octaveStart}`"
                        append-outer-icon="$vuetify.icons.edit"
                        color="accent"/>
        </select-note>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        To
      </v-col>
      <v-col>
        <select-note :pitch-class="pitchClassEnd"
                     pitch-class-var="pitchClassEnd"
                     :octave="octaveEnd"
                     octave-var="octaveEnd"
                     :octaves="[2,3,4,5,6]"
                     @updated="setState">
          <v-text-field hide-details dense readonly :value="`${pitchClassEnd}${octaveEnd}`"
                        append-outer-icon="$vuetify.icons.edit"
                        color="accent"/>
        </select-note>
      </v-col>
    </v-row>
    <slot name="other-setup"/>
    <app-divider>
      <span class="font-weight-medium title">
        Other options
      </span>
    </app-divider>
    <v-row align="center">
      <v-col>
        Analyze My performance
        <v-btn icon x-small
               @click="showHelp('Use pitch detection to analyze your performance and show you how accurate you were after the exercise.')">
          <app-icon icon="help" size="18"/>
        </v-btn>
      </v-col>
      <v-col align="center">
        <v-switch hide-details v-model="isAnalyze"
                  color="accent"
                  class="pa-0 ma-0"
                  style="display: inline-block"/>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        Interactive Mode
        <v-btn icon x-small @click="showHelp('Whether to Leave time for you to sing the notes without the piano at the end of each step.')">
          <app-icon icon="help" size="18"/>
        </v-btn>
      </v-col>
      <v-col align="center">
        <v-switch hide-details v-model="isInteractive"
                  color="accent"
                  class="pa-0 ma-0"
                  style="display: inline-block"/>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        Metronome
      </v-col>
      <v-col align="center">
        <v-switch hide-details v-model="isMetronomeActive"
                  color="accent"
                  class="pa-0 ma-0"
                  style="display: inline-block;"/>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        Speed(BPM)
      </v-col>
      <v-col>
        <v-text-field dense hide-details
                      color="accent"
                      v-model="bpm"
                      type="number"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-col cols="12" class="py-0"
                 @click="$emit('set-exercise-property', {prop: 'isTimerActive', val: !isTimerActive})">
            Timer [Seconds]
          </v-col>
          <v-col>
            <v-checkbox hide-details v-model="isTimerActive" color="accent" class="d-inline"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-text-field dense hide-details
                      color="accent"
                      :disabled="!isTimerActive"
                      v-model="timer"
                      type="number"/>
      </v-col>
    </v-row>
    <div style="position: sticky; bottom: 0; right: 0; left: 0">
      <v-btn block x-large elevation="4"
             color="success darken-1"
             class="text-h5 font-weight-black text-capitalize"
             @click="startExercise">
        Let's Go!
      </v-btn>
    </div>
  </div>
</template>
<script>
import AppDivider                              from 'framework/ui/components/AppDivider'
import SelectNote                              from 'sections/SelectNote'
import { mapActions, mapGetters }              from 'vuex'
import { DEFAULT_OCTAVE, DEFAULT_PITCH_CLASS } from 'framework/resources/framework-constants'
import { RANGE_END }                           from 'misc/app-constants'

export default {
  name:       'SetupExercise',
  components: { AppDivider, SelectNote },
  data() {
    return {
      isAnalyze:     true,
      isInteractive: false,
      isTimerActive: false,
      timerMS:         30000,
    }
  },
  computed:   {
    ...mapGetters('transport', ['getTransportBPM']),
    ...mapGetters('metronome', ['getIsMetronomeActive']),
    ...mapGetters('context', ['getPitchClass', 'getOctave', 'getPitch']),

    pitchClassStart: {
      get() {
        return this.getPitchClass(DEFAULT_PITCH_CLASS)
      },
      set(pitchClass) {
        this.$store.dispatch('context/setPitchClass', { pitchClass })
      }
    },

    pitchClassEnd: {
      get() {
        return this.getPitchClass(RANGE_END)
      },
      set(pitchClass) {
        this.$store.dispatch('context/setPitchClass', { key: RANGE_END, pitchClass })
      }
    },

    octaveStart: {
      get() {
        return this.getOctave(DEFAULT_OCTAVE)
      },
      set(octave) {
        this.$store.dispatch('context/setOctave', { octave })
      }
    },

    octaveEnd: {
      get() {
        return this.getOctave(RANGE_END)
      },
      set(octave) {
        this.$store.dispatch('context/setOctave', { key: RANGE_END, octave })
      }
    },

    isMetronomeActive: {
      get() {
        return this.getIsMetronomeActive
      },
      set(active) {
        this.setMetronome({ active })
      }
    },

    bpm: {
      get() {
        return this.getTransportBPM
      },
      set(val) {
        val && this.setBPM(parseInt(val))
      }
    },

    timer: {
      get() {
        return this.timerMS / 1000
      },
      set(val) {
        this.timerMS = val * 1000
      }
    }
  },
  methods:    {
    ...mapActions('transport', ['setBPM']),
    ...mapActions('metronome', ['setMetronome']),
    ...mapActions('view', ['setSnackbar']),
    setState({ setting, value }) {
      this[setting] = value
    },

    showHelp(text) {
      this.setSnackbar({ text, timeout: 4000, active: true })
    },

    startExercise() {
      const start = this.getPitch()
      const end   = this.getPitch(RANGE_END)
      const query = `from=${ start }&to=${ end }&bpm=${ this.getTransportBPM }&time=${ this.isTimerActive ? this.timerMS : 'false' }&interactive=${ this.isInteractive }&analyze=${this.isAnalyze}`
      this.$emit('start-exercise', query)
    }
  }
}
</script>